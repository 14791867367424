import {
	AbstractUniversalEntityForm,
	AbstractUniversalFormProps,
	AbstractUniversalFormStates,
} from "../reusableComponents/UniversalForm";
import { SidePanelForm } from "../reusableComponents/SidePanelForm";
import { ReactNode, forwardRef, useImperativeHandle, useRef } from "react";

interface BasicFormProps extends AbstractUniversalFormProps {
	title?: string;
}
type BasicFormStates = AbstractUniversalFormStates & { title: string };

class BasicFormInner extends AbstractUniversalEntityForm<
	BasicFormProps,
	BasicFormStates
> {
	constructor(props: {}) {
		super(props);

		this.state = { isEnabled: true, title: "" };
	}

	public reset() {
		console.log("BasicForm: reseted");
	}

	public validate(): string | boolean {
		console.log("BasicForm: validated");
		return true;
	}

	public submit() {
		console.log("BasicForm: submited");
	}

	public enable() {
		this.setState({ isEnabled: true });
	}

	public disable(): void {
		this.setState({ isEnabled: false });
	}

	public isEnabled(): boolean {
		return this.state.isEnabled;
	}

	public setPrimaryField(value: string): void {
		this.setState({ title: value });
	}

	render(): ReactNode {
		return (
			<div className="form-card">
				<p className="title">Basic form</p>
			</div>
		);
	}
}

const BasicForm = forwardRef<AbstractUniversalEntityForm, BasicFormProps>(
	(props, ref) => {
		const innerRef = useRef<BasicFormInner>(null);

		useImperativeHandle(
			ref,
			() => innerRef.current as AbstractUniversalEntityForm,
			[innerRef]
		);

		return <BasicFormInner {...props} ref={innerRef} />;
	}
);

export function NewSidePanelTest() {
	let sidePanelRef = useRef<SidePanelForm>(null);

	return (
		<>
			<div>
				<button
					onClick={() => {
						sidePanelRef.current?.setIsOpen(true);
					}}
				>
					OPEN SIDE BAR
				</button>
				<SidePanelForm ref={sidePanelRef} form={BasicForm} />
			</div>
		</>
	);
}
