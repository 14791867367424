
import LeftPanel from "./LeftPanel";
import SignInForm from "./SignInForm";
import "./Login.css";


function Login(){

    return <>
        <LeftPanel />
        <SignInForm />
    </>
}

export default Login;