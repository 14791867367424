import { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { UserProfile } from "./entities";
import { cookies } from "./Cookies";
import { NewSidePanelTest } from "./Pages/NewSidePanelTests";

const CheckStrategiesTest = lazy(
	() => import("./Pages/CheckStrategies/CheckStrategies2")
);
const CheckStrategies = lazy(
	() => import("./Pages/CheckStrategies/CheckStrategies")
);
const Dashboard = lazy(() => import("./Pages/Dashboard"));
const DashboardTodoListView = lazy(
	() => import("./Dashboard/01_pages/dashboard/Dashboard")
);
const Settings = lazy(() => import("./Pages/Settings"));

function Content() {
	let user: UserProfile | null = null;
	if (cookies.get("jwt-token") !== undefined) {
		user = jwtDecode<UserProfile>(cookies.get("jwt-token"));
	}

	// let i = 0;
	// while (true){
	//   i++;
	//   try{
	//     user = jwtDecode<UserProfile>(cookies.get('jwt-token'));
	//     console.log(globalState.user?.email);
	//     console.log(user?.email);
	//     if (globalState.user?.email != user?.email){
	//       setGlobalState((prev) => ({ ...prev, ...{user: user} as GlobalStateInterface }));
	//     }
	//     else if(globalState.user?.email == user?.email || user === undefined){
	//       break;
	//     }
	//     if (i>3){
	//       break
	//     }

	//   } catch{
	//     break;
	//   }
	// }

	useEffect(() => {
		console.log("Use Effect (Componnent did mount | Content)");
	}, []);

	console.log("Content:");
	console.log(user?.email);

	return (
		<div id="content">
			<>
				{user !== null ? (
					<>
						<Suspense fallback={<div>Loading...</div>}>
							<Routes>
								<Route
									path="/check-strategies/old-design"
									element={<CheckStrategies />}
								/>
								<Route
									path="/check-strategies"
									element={<CheckStrategiesTest />}
								/>
								<Route
									path="/dashboard/old"
									element={<Dashboard />}
								/>

								<Route
									path="/dashboard"
									element={<DashboardTodoListView />}
								/>
								<Route
									path="/settings"
									element={<Settings />}
								/>
								<Route
									path="/test/side-panel"
									element={<NewSidePanelTest />}
								/>
							</Routes>
						</Suspense>
					</>
				) : (
					<>
						{console.log("redirect")}
						<Navigate to={"/"} />
					</>
				)}
			</>
		</div>
	);
}

export default Content;
