import { useState } from "react";
import { CodeResponse, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { cookies } from "../../Cookies";
import { BACKEND_HOST } from "../../CONFIG";
import { UserProfile } from "../../entities";
import { Navigate } from "react-router-dom";
import { motion, Variants } from "framer-motion";

function SignInForm() {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	let u: UserProfile | null = null;
	if (cookies.get("jwt-token") !== undefined) {
		u = jwtDecode<UserProfile>(cookies.get("jwt-token"));
	}

	const [user, setUser] = useState(u);

	async function makeAuthRequest(CodeResponse: CodeResponse) {
		return await axios
			.post("http://" + BACKEND_HOST + "/auth-google/", {
				auth_code: CodeResponse.code,
			})
			.then((response) => {
				console.log(response.data.jwt_token);
				cookies.set("jwt-token", response.data.jwt_token);
			});
	}

	const login = useGoogleLogin({
		flow: "auth-code",
		onSuccess: async (CodeResponse) => {
			console.log(CodeResponse);
			try {
				setLoading(true);
				await makeAuthRequest(CodeResponse);
				setLoading(false);
				setError(false);
				setUser(jwtDecode<UserProfile>(cookies.get("jwt-token")));
			} catch {
				setLoading(false);
				setError(true);
			}
		},
	});

	return (
		<div className="sign-in-form">
			{user !== null && (
				<>
					{console.log("Navigate")}
					<Navigate to="/dashboard/" />
				</>
			)}
			<motion.div
				initial={{ scale: 0, opacity: 0 }}
				animate={{ scale: 1, opacity: 1, transition: { delay: 0.4 } }}
				whileHover={{ scale: [null, 1.1] }}
			>
				<div className="form-box">
					<p className="title">Sign In</p>
					<div className="description">
						In order to start using our tool you need to log in or register with
						your Google account that you use for Google Ads. Please note that
						you can only do checks for manager accounts using our tool.
					</div>
					<motion.div
						initial={{ scale: 1, marginLeft: 0 }}
						whileHover={{ scale: [null, 1.1] }}
						whileTap={{ scale: [null, 0.8] }}
					>
						<p className="google-auth-button" onClick={() => login()}>
							{loading ? "Loading.." : "Cotinue with Google"}
						</p>
					</motion.div>
				</div>
			</motion.div>
		</div>
	);
}

export default SignInForm;
