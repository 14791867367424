import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { UserProfile } from "../entities";
import { cookies } from "../Cookies";
import "./Home.css";

function Home() {
	let user: UserProfile | null = null;
	if (cookies.get("jwt-token") !== undefined) {
		user = jwtDecode<UserProfile>(cookies.get("jwt-token"));
	}

	return (
		<div>
			<h1>This is the landing page of Ads Cockpit</h1>
			{user !== null ? (
				<>
					<div>
						<p style={{ display: "inline-block", marginRight: "48px" }}>
							Hi {user.name}
						</p>
						<Link to="/logout/">Log Out</Link>
					</div>
					<Link to="/dashboard/">I want to get back to my Dashboard</Link>
				</>
			) : (
				<Link to="/login/"> Log In </Link>
			)}
		</div>
	);
}

export default Home;
