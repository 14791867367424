import {
	Component,
	ComponentType,
	FormEvent,
	ReactNode,
	createRef,
} from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
	AbstractUniversalEntityForm,
	AbstractUniversalFormProps,
} from "./UniversalForm";
import "./SidePanelForm.css";

interface SidePanelFormProps {
	form: ComponentType<any>;
	afterSubmit?: (obj: SidePanelForm) => void;
	title?: string;
	preValues?: AbstractUniversalFormProps;
}

type SidePanelFormState = {
	isOpen: boolean;
	title: string;
	messages: string[];
	submitable: boolean;
};

export class SidePanelForm extends Component<
	SidePanelFormProps,
	SidePanelFormState
> {
	private formRef = createRef<AbstractUniversalEntityForm>();
	private sidePanelBodyRef = createRef<HTMLDivElement>();

	private initTitle: string;

	constructor(props: SidePanelFormProps) {
		super(props);

		this.initTitle = props.title ? props.title : "New Check";

		this.state = {
			isOpen: false,
			title: this.initTitle,
			messages: ["Attension! Description must be filled in"],
			submitable: true,
		};

		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	handleClickOutside(e: MouseEvent) {
		if (
			this.sidePanelBodyRef.current &&
			!this.sidePanelBodyRef.current.contains(e.target as Node)
		) {
			this.setIsOpen(false);
		}
	}

	render(): ReactNode {
		return (
			<AnimatePresence>
				{this.state.isOpen && (
					<motion.div
						className="sidepanel-form"
						initial={{ backgroundColor: "rgba(0,0,0,0.0)" }}
						animate={{
							backgroundColor: "rgba(0,0,0,0.2)",
							transition: { velocity: 0.3 },
						}}
						exit={{
							transition: { delay: 0.3 },
							backgroundColor: "rgba(0,0,0,0.0)",
						}}
					>
						<motion.div
							className="sidepanel-body"
							ref={this.sidePanelBodyRef}
							initial={{ width: "0%", overflow: "hidden" }}
							animate={{
								width: "70%",
								overflow: "hidden",
								transitionEnd: { overflow: "auto" },
								transition: { velocity: 0.3 },
							}}
							exit={{
								transition: { delay: 0.3 },
								width: "0%",
								overflow: "hidden",
							}}
						>
							<motion.div
								initial={{ opacity: 0 }}
								animate={{
									transition: { delay: 0.3, velocity: 0.5 },
									opacity: 1,
								}}
								exit={{ opacity: 0, transition: { velocity: 0.3 } }}
							>
								<div className="settings-bar">
									<div className="left-side">
										<motion.svg
											xmlns="http://www.w3.org/2000/svg"
											width="30"
											height="30"
											fill="currentColor"
											className="bi bi-x close-icon clickable"
											viewBox="0 0 16 16"
											whileHover={{ scale: this.state.submitable ? 1.2 : 1 }}
											whileTap={{ scale: this.state.submitable ? 0.9 : 1 }}
											onClick={() => {
												this.setIsOpen(false);
											}}
										>
											<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
										</motion.svg>
										<p
											className="title"
											contentEditable
											onInput={(e: FormEvent<HTMLParagraphElement>) => {
												console.log("got input");
												console.log(e.currentTarget.textContent as string);
												this.setTitle(e.currentTarget.textContent as string);
											}}
										>
											{this.initTitle}
										</p>
									</div>
									<motion.div
										className={
											this.state.submitable
												? "save-button clickable"
												: "save-button disable"
										}
										whileTap={{ scale: 0.9 }}
										whileHover={{ scale: 1.1 }}
										onClick={async () => {
											console.log("submitButton.onClick:");
											console.log(this.formRef.current);
											if (!this.state.submitable) {
												return;
											}
											this.setSubmitable(false);
											this.formRef.current?.disable();
											console.log(this.formRef.current);
											console.log(this.state.title);
											this.formRef.current?.setPrimaryField(this.state.title);
											console.log(this.formRef.current);
											let validationResult = this.formRef.current?.validate();
											console.log(this.formRef.current);
											if (validationResult === true) {
												this.formRef?.current?.submit();
												await new Promise((f) => setTimeout(f, 1000));
												this.formRef.current?.enable();
												this.setIsOpen(false);
												await new Promise((f) => setTimeout(f, 1000));
												this.setSubmitable(true);
											} else {
												this.pushMessage(validationResult as string);
												this.formRef.current?.enable();
												this.setSubmitable(true);
											}
											if (this.props.afterSubmit) {
												this.props.afterSubmit(this);
											}
										}}
									>
										<span className="text">Save</span>
									</motion.div>
								</div>
								<div className="content">
									<this.props.form
										ref={this.formRef}
										{...this.props.preValues}
									/>
								</div>
							</motion.div>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
		);
	}

	private pushMessage(message: string) {
		this.setState({ messages: this.state.messages.concat([message]) });
	}

	private setSubmitable(submitable: boolean) {
		this.setState({ submitable: submitable });
	}

	private setTitle(title: string) {
		this.setState({ title: title });
	}

	public setIsOpen(isOpen: boolean) {
		this.setState({ isOpen: isOpen });
	}

	public getForm(): AbstractUniversalEntityForm | null {
		console.log("getForm():");
		console.log(this.formRef.current);
		return this.formRef.current;
	}
}
