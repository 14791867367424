import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { motion } from "framer-motion";
import LeftPanel from "./LeftPanel";
import Content from "./Content";
import Home from "./Pages/Home";
import Login from "./Pages/Login/Login";
import { UserProfile } from "./entities";
import { cookies } from "./Cookies";
import "./App.css";

interface LogoutProps {
	setUser: React.Dispatch<React.SetStateAction<UserProfile | null>>;
}

function Logout(props: LogoutProps) {
	async function deleteCookie(path: string, domain: string) {
		return await cookies.remove("jwt-token", { path: path, domain: domain });
	}

	useEffect(() => {
		console.log("logout");
		deleteCookie("/", "localhost");
		deleteCookie("/login", "localhost");
		deleteCookie("/", "app.adscockpit.io");
		deleteCookie("/login", "app.adscockpit.io");
		props.setUser(null);
	});

	return <></>;
}

function App() {
	let u: UserProfile | null = null;
	if (cookies.get("jwt-token") !== undefined) {
		u = jwtDecode<UserProfile>(cookies.get("jwt-token"));
		console.log(u);
	}
	const [user, setUser] = useState(u);
	console.log(console.log(user));

	document.title = "Ads Cockpit";

	useEffect(() => {
		console.log("Use Effect (Componnent did mount | App)");
	}, []);

	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/login/" element={<Login />} />
					<Route
						path="/logout/"
						element={
							<>
								{user === null ? (
									<Navigate to="/" />
								) : (
									<Logout setUser={setUser} />
								)}
							</>
						}
					/>
					<Route
						path="*"
						element={
							<>
								<LeftPanel />
								<motion.div
									style={{ width: "100%", margin: 0 }}
									initial={{ opacity: 0 }}
									animate={{
										opacity: 1,
										transition: { duration: 0.3, delay: 0.4 },
									}}
								>
									<Content />
									<div id="right-panel"></div>
								</motion.div>
							</>
						}
					/>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
