// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  text-decoration: none !important;
  color: inherit !important;
}

#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

#left-panel {
  height: 100vh;
  border-right: 3px solid #dfdfe9;
  width: auto;
  max-width: 355px;
  position: sticky;
  top: 0;
}

#content {
  padding-left: 50px;
  padding-right: 50px;
  max-width: 840px;
  height: 100%;
}

.pagename-check-strategy, .pagename-dashboard, .pagename-settings {
  padding-top: 44px;
  font-size: var(--xl-font-size);
  font-weight: 700;
  margin-bottom: var(--m-space);
  margin-top: 0;
}

.chapter-headline-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:end;
  margin-bottom: var(--s-space);
}

.chapter-headline {
  font-size: 26px;
  font-weight: 600;
  margin: 0;
}

.chapter-right-side-button {
  padding-right: 30px;
  color: #9095ab;
  text-align: bottom;
  font-size: 20px;
  font-weight: 600;
}

#right-panel {
  width: 20%;
  max-width: 250px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,+BAA+B;EAC/B,WAAW;EACX,gBAAgB;EAEhB,gBAAgB;EAChB,MAAM;AACR;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,8BAA8B;EAC9B,gBAAgB;EAChB,6BAA6B;EAC7B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["a {\n  text-decoration: none !important;\n  color: inherit !important;\n}\n\n#root {\n  max-width: 1280px;\n  margin: 0 auto;\n  padding: 2rem;\n  text-align: center;\n}\n\n#left-panel {\n  height: 100vh;\n  border-right: 3px solid #dfdfe9;\n  width: auto;\n  max-width: 355px;\n  position: -webkit-sticky;\n  position: sticky;\n  top: 0;\n}\n\n#content {\n  padding-left: 50px;\n  padding-right: 50px;\n  max-width: 840px;\n  height: 100%;\n}\n\n.pagename-check-strategy, .pagename-dashboard, .pagename-settings {\n  padding-top: 44px;\n  font-size: var(--xl-font-size);\n  font-weight: 700;\n  margin-bottom: var(--m-space);\n  margin-top: 0;\n}\n\n.chapter-headline-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items:end;\n  margin-bottom: var(--s-space);\n}\n\n.chapter-headline {\n  font-size: 26px;\n  font-weight: 600;\n  margin: 0;\n}\n\n.chapter-right-side-button {\n  padding-right: 30px;\n  color: #9095ab;\n  text-align: bottom;\n  font-size: 20px;\n  font-weight: 600;\n}\n\n#right-panel {\n  width: 20%;\n  max-width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
