import React, { Component } from "react";

export interface AbstractUniversalFormProps {
	forwardedRef?: React.ForwardedRef<AbstractUniversalEntityForm>;
}

export type AbstractUniversalFormStates = {
	isEnabled: boolean;
};

export abstract class AbstractUniversalEntityForm<
	P = {},
	S = {}
> extends Component<P & AbstractUniversalFormProps, S> {
	abstract reset(): void;
	abstract validate(): string | boolean;
	abstract submit(): void;
	abstract enable(): void;
	abstract disable(): void;
	abstract isEnabled(): boolean;
	abstract setPrimaryField(value: string): void;

	public getJSON() {
		return {};
	}
}
