import { motion, Variants } from "framer-motion";

function LeftPanel() {
	const left_panel_variants = {
		initial: {
			width: 0,
		},
		animate: {
			width: "100%",
			transition: {
				duration: 0.2,
				when: "beforeChildren",
			},
		},
	};

	const opacity_variants = {
		initial: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
			transition: {
				duration: 0.2,
				when: "beforeChildren",
			},
		},
	};

	return (
		<motion.div
			className="left-panel"
			initial="initial"
			animate="animate"
			variants={left_panel_variants}
		>
			<div className="flex-container">
				<motion.div className="left-panel-logo" variants={opacity_variants}>
					<a href="/">
						<motion.p
							initial={{ scale: 1 }}
							whileHover={{ scale: [null, 1.1] }}
							whileTap={{ scale: [null, 0.8] }}
							style={{ cursor: "pointer" }}
						>
							Ads Cockpit
						</motion.p>
					</a>
				</motion.div>
				<motion.div className="middle-text" variants={opacity_variants}>
					<p className="welcome-text">Welcome to Ads Cockpit!</p>
					<p className="subtext">The best tool for Google Ads monitoring</p>
				</motion.div>
				<motion.div className="down-text" variants={opacity_variants}>
					<p>© 2024 Ads Cockpit</p>
				</motion.div>
			</div>
		</motion.div>
	);
}

export default LeftPanel;
