// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-info-container {
  margin-left: 25px;
  margin-top: 44px;
  height: 64px;
  display: table-cell;
}

.profile-picture-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.05);
}

.profile-picture-container {
  float: left;
}

.user-name-text {
  font-size: 22px;
  font-weight: 600;
  margin-left: var(--s-space);
  margin-top: 0;
}

.user-name-container {
  margin: 0;
  height: 70px;
  width: 70px;
  line-height: 70px;
  float: left;
}

.navigation-bar-left-panel-container {
  margin-left: 50px;
}

.navigation-bar-left-panel-icon {
  width: 25px;
}

.navigation-bar-left-panel-element {
  font-size: var(--s-font-size);
  margin-top: var(--m-space);
  margin-bottom: var(--m-space);
}

.navigation-bar-left-panel-text {
  margin-left: var(--xxs-space);
  font-weight: 700;
  color: #9095ab;
}

.navigation-bar-left-panel-text-active {
  margin-left: var(--xxs-space);
  font-weight: 800;
  color: #202a67;
}

.logo-left-panel-container {
  margin-left: 25px;
}

.logo-left-panel-text {
  font-size: var(--m-font-size);
  font-weight: 800;
}
`, "",{"version":3,"sources":["webpack://./src/LeftPanel.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAGlB,+CAA+C;AACjD;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,2BAA2B;EAC3B,aAAa;AACf;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,6BAA6B;EAC7B,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;AAClB","sourcesContent":[".account-info-container {\n  margin-left: 25px;\n  margin-top: 44px;\n  height: 64px;\n  display: table-cell;\n}\n\n.profile-picture-img {\n  width: 70px;\n  height: 70px;\n  border-radius: 50%;\n  -webkit-box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.05);\n  -moz-box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.05);\n  box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.05);\n}\n\n.profile-picture-container {\n  float: left;\n}\n\n.user-name-text {\n  font-size: 22px;\n  font-weight: 600;\n  margin-left: var(--s-space);\n  margin-top: 0;\n}\n\n.user-name-container {\n  margin: 0;\n  height: 70px;\n  width: 70px;\n  line-height: 70px;\n  float: left;\n}\n\n.navigation-bar-left-panel-container {\n  margin-left: 50px;\n}\n\n.navigation-bar-left-panel-icon {\n  width: 25px;\n}\n\n.navigation-bar-left-panel-element {\n  font-size: var(--s-font-size);\n  margin-top: var(--m-space);\n  margin-bottom: var(--m-space);\n}\n\n.navigation-bar-left-panel-text {\n  margin-left: var(--xxs-space);\n  font-weight: 700;\n  color: #9095ab;\n}\n\n.navigation-bar-left-panel-text-active {\n  margin-left: var(--xxs-space);\n  font-weight: 800;\n  color: #202a67;\n}\n\n.logo-left-panel-container {\n  margin-left: 25px;\n}\n\n.logo-left-panel-text {\n  font-size: var(--m-font-size);\n  font-weight: 800;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
