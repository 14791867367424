import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalStateProvider } from "./GlobalStateProvider";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<>
		{/* <React.StrictMode>/ */}
		<GoogleOAuthProvider clientId="718661231488-jap34594pb5pm4rqddvrmrnjmk73ijqr.apps.googleusercontent.com">
			<GlobalStateProvider>
				<App />
			</GlobalStateProvider>
		</GoogleOAuthProvider>
		{/* </React.StrictMode> */}
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
